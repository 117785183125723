<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Category Content
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="12">
            <b-col cols="12">
              <b-form-group
                label="Description"
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                
                <quill-editor
                  ref="classicDescription"
                  v-model="addCategoryContent.classic"
                  :options="getEditorOptions('classicDescription')"
                />
                 
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Online Description "
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >

                  <quill-editor
                  ref="onlineDescription"
                  v-model="addCategoryContent.online"
                  :options="getEditorOptions('onlineDescription')"
                />
                                 <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
            data-testid="category-submit-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import Editor from '@tinymce/tinymce-vue'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive, computed ,getCurrentInstance} from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.snow.css'
import Vue from 'vue'
import store from '@/store'
// Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  setup(props) {
    store.dispatch('content/GetCategoryContent').then(response => {
      const data = null
      store.dispatch('content/GetCategoryContentOnline').then(res => {
        const data = {
          onlineId: res.data.data.data[0].id,
          onlineName: res.data.data.data[0].name,
          classicId: response.data.data.data[0].id,
          classicName: response.data.data.data[0].name,
          online: res.data.data.data[0].value,
          classic: response.data.data.data[0].value,
        }
        store.commit('content/CategoryContent', data)
      })
    })

    const CategoryContent = computed(() => store.state.content.CategoryContent)
    const addCategoryContent = reactive(CategoryContent)

    const addCategoryContentvalidate = ref()

    const save = () => {
      const formDataClassic = new FormData()

      formDataClassic.append('name', addCategoryContent.value.classicName)
      formDataClassic.append('value', addCategoryContent.value.classic)
      const { classicId } = addCategoryContent.value
      const formDataOnline = new FormData()
      formDataOnline.append('name', addCategoryContent.value.onlineName)
      formDataOnline.append('value', addCategoryContent.value.online)
      const { onlineId } = addCategoryContent.value

      //  addCategoryContentvalidate.value.validate().then(success => {
      //         if (success) {
      store
        .dispatch('content/UpdateCategoryContentClassic', {
          classicId,
          formDataClassic,
        })
        .then(response => {
          store
            .dispatch('content/UpdateCategoryContentOnline', {
              onlineId,
              formDataOnline,
            })
            .then(response => {
              Vue.swal({
                icon: 'success',
                title: 'Updated!',

                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        })
      // }})
    }
    function example_image_upload_handler(blobInfo, success, failure, progress) {
      let xhr; let
        formData
      const token = localStorage.getItem('token')

      xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)

      xhr.upload.onprogress = function (e) {
        progress(e.loaded / e.total * 100)
      }
      xhr.setRequestHeader('Authorization', `Bearer ${token}`)
      xhr.onload = function () {
        let json

        if (xhr.status === 403) {
          failure(`HTTP Error: ${xhr.status}`, { remove: true })
          return
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          failure(`HTTP Error: ${xhr.status}`)
          return
        }

        json = JSON.parse(xhr.responseText)

        if (!json || typeof json.location !== 'string') {
          failure(`Invalid JSON: ${xhr.responseText}`)
          return
        }

        success(json.location)
      }

      xhr.onerror = function () {
        failure(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
      }

      formData = new FormData()
      formData.append('image', blobInfo.blob(), blobInfo.filename())

      xhr.send(formData)
    }
      const { proxy } = getCurrentInstance()
    const imageProperties = reactive({
      src: '',
      alt: '',
      width: '',
      height: '',
      alignment: '',
    })
    let editorSelection = null
    let currentEditor = null

    const imageHandler = editorRef => {
      currentEditor = proxy.$refs[editorRef].quill
      editorSelection = currentEditor.getSelection()
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = async () => {
        const file = input.files[0]
        const formData = new FormData()
        formData.append('image', file)

        try {
          const token = localStorage.getItem('token')
          const xhr = new XMLHttpRequest()
          xhr.withCredentials = false
          xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)

          xhr.onload = function () {
            if (xhr.status === 403) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            const json = JSON.parse(xhr.responseText)

            if (!json || typeof json.location !== 'string') {
              console.error(`Invalid JSON: ${xhr.responseText}`)
              return
            }

            imageProperties.src = json.location
            proxy.$bvModal.show('image-properties-modal')
          }

          xhr.onerror = function () {
            console.error(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
          }

          xhr.send(formData)
        } catch (error) {
          console.error('Image upload failed:', error)
        }
      }
    }

    const insertImage = () => {
      const editor = currentEditor
    console.log(currentEditor.getSelection())
      const range = editorSelection
      if (!range) {
        console.error('No selection found')
        return
      }
      const { src, alt, width, height, alignment } = imageProperties
  
      editor.insertEmbed(range.index, 'image', { src, alt, width, height, class: alignment })
      proxy.$bvModal.hide('image-modal-post')
         imageProperties.src = '';
      imageProperties.alt = '';
      imageProperties.width = '';
      imageProperties.height = '';
      imageProperties.alignment = '';
    }


  const linkHandler = editorRef => {
  const range = proxy.$refs[editorRef].quill.getSelection()
  if (range) {
    const url = prompt('Enter the URL')
    if (url) {
      const openInNewTab = confirm('Open in new tab?')
      const selectedText = proxy.$refs[editorRef].quill.getText(range)
      const targetAttribute = openInNewTab ? ' target="oooooooooo" rel="noopener noreferrer"' : ''
      const linkValue = `<a href="${url}"${targetAttribute}>${selectedText}</a>`

      // Remove the selected text
      proxy.$refs[editorRef].quill.deleteText(range.index, range.length)

      // Insert the link at the original selection index
      proxy.$refs[editorRef].quill.clipboard.dangerouslyPasteHTML(range.index, linkValue)
    }
  }
}

    const getEditorOptions = editorRef => ({
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image', 'video'],
            [{ size: SizeStyle.whitelist }],
    ['bold', 'italic', 'underline', 'strike'],
  
    ['clean'],
    ['undo', 'redo'],
    ['paragraph']
          ],
          handlers: {
            image: () => imageHandler(editorRef),
            link: () => linkHandler(editorRef)
          },
        },
      },
    })

    return {
      example_image_upload_handler,
      addCategoryContent,

      addCategoryContentvalidate,

      CategoryContent,
  linkHandler,
       imageHandler,
      insertImage,
      imageProperties,
      getEditorOptions,
      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    Editor,
    quillEditor
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
.mb-2.col-12 {
  margin: -1px !important;
}
.border.rounded.p-2 {
  margin: 8px 1px !important;
}
.col-12 {
  margin: 0px;
}
</style>
